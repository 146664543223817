import { Application } from "@hotwired/stimulus";

const application = Application.start()

// Import vendors controllers
import TomSelectController from "./vendors/tom_select_controller";
application.register("tom-select", TomSelectController);

import DatepickerController from "./vendors/datepicker_controller";
application.register("datepicker", DatepickerController);
