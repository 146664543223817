console.log('Vite ⚡️ Rails')

// Import Turbo Hotwire
import "@hotwired/turbo-rails"
import TurboPower from "turbo_power"

TurboPower.initialize(Turbo.StreamActions)
Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target)
}

// Import Stimulus controllers
import "../javascript/controllers"

// Import plugins
import "../javascript/plugins"

// Import stylesheets
import '../stylesheets/application.css'
